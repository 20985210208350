<template>
  <div class="with-anchors data-visualisation">
    <AnchorMenu :anchors="anchors" />
    <h1 id="intro">Data visualisation</h1>
    <p>Data is the core of our business. Everyday, data keep our users moving, secured and connected. 
This is because data help users make informed decisions. Therefore, data should be presented in a meaningful way. Below are a few standards that we've set for telling visual data stories.</p>

    <!-- HOW TO START -->
    <section>
      <h2 id="start" class="display-m">How to start?</h2>

      <h3>Templates</h3>
      <p>We built <a href="https://app.powerbi.com/links/KGYzOqnDUf?ctid=c2f52191-1cd3-40d7-a02c-b8a024896337&pbi_source=linkShare" target="_blank">several templates</a> that you can use as a starting point. Explore and pick the one that suits better the purpose of your report. </p>

      <h3>Layout</h3>
      <p>Our charts are most of the times part of a bigger report normally displayed in 16:9 screen format. To adapt to diverse screen size and orientation, we use a 12-column grid as follow:</p>

      <img src="../../assets/img/visualisation/img-grid.png" alt="" role="presentation" width="600px" />

      <p>This ensure enough flexibility to organise charts in hierarchy. There are many possible layouts including, but not limited to:</p>

      <img src="../../assets/img/visualisation/img-layout.png" alt="" role="presentation" />

      <p>Remember: keep it simple to help users to understand the data. Use adittional pages in case a more detailed view is needed. </p>
    </section>

    <!-- TYPE OF CHARTS -->
    <section>
      <h2 id="types">Type of charts</h2>
      <h3>Line charts</h3>
      <p>Use solid lines to indicate actual trends in data. <br/>
      Lines should be 2pts in weight and straight, never curved.<br/><br/>
      To build the grid use dotted lines 1pt in weight in RB70. </p>
      
      <img src="../../assets/img/visualisation/img-line-chart.png" alt="" role="presentation" />
      <img src="../../assets/img/visualisation/img-line-chart-tooltip.png" alt="" role="presentation" />

      <h3>Bar charts</h3>
      <p>Don't apply rounded corners.<br/><br/>
      The space between bars should always be 50% of the width of the bars.<br/><br/>
      Generally, categories in stacked bar charts should use one of our pre-configured categorical color combos. Avoid using the same hue to represent categories. If subcategories need to be made, use our hatch pattern if applicable<br/><br/>
      To build the grid use dotted lines 1pt in weight in RB70.</p>
      <img src="../../assets/img/visualisation/img-bar-chart_single-bar.png" alt="" role="presentation" />
      <img src="../../assets/img/visualisation/img-bar-chart_segmented-bar.png" alt="" role="presentation" />
      <img src="../../assets/img/visualisation/img-bar-chart_multiple-bars.png" alt="" role="presentation" />
      <img src="../../assets/img/visualisation/img-bar-chart_multiple-bar_tooltip.png" alt="" role="presentation" />

      <h3>Rings and pie charts</h3>
      <p>Always use a categorical color combo. </p>
      <img src="../../assets/img/visualisation/img-ring-pie-half-ring_charts.png" alt="" role="presentation" />

      <h3>Figures</h3>
      <p>Figures usually help to get quick insights from a chart. You can use them in a standalone white card, group of cards or as a part of a standardised chart. Make sure the figure is Royal Blue colour (RB) and subtitle is in RB70.  </p>
      <img src="../../assets/img/visualisation/img-figures.png" alt="" role="presentation" />

      <h3>Tables</h3>
      <img src="../../assets/img/visualisation/img-table.png" alt="" role="presentation" />
    </section>

    <!-- GRIDS -->
    <section>
      <h2 id="grids">Grids and labels</h2>
      <p>We use PowerBi to built most of our charts. Unfortunately, PowerBi does NOT support Open Sans to build reports, that's why we use Arial instead to keep consistency across our visual elements. </p>

      <h3>Title</h3>
      <p>Use a simple title to describe clearly the purpose of the chart. <br/>
        Titles use Arial Regular 14pt RB.</p>
      <img src="../../assets/img/visualisation/img-card-title.png" alt="" role="presentation" width="500px"/>

      <h3>X-Axis baseline</h3>
      <p>The baseline is a 1pt RB70 line. Labels are in Arial Regular 10pt RB70. <br/>
      You can use an extra label as a heading if that makes the chart clearer.</p>
      <img src="../../assets/img/visualisation/img-X-axis.png" alt="" role="presentation"/>

      <h3>Y-Axis </h3>
      <p>The y-axis is usually represented with a label in Arial Regular 10pt RB70 and a 1pt RB40 dotted grid line. <br/> <br/>
      You can use an extra vertical label as a heading if that makes the chart clearer.</p>
      <img src="../../assets/img/visualisation/img-Y-axis.png" alt="" role="presentation" />

      <h3>Legend </h3>
      <p>Use a simple circle to the left side of the label. Label should be in Arial Regular 10pt RG70.</p>
      <img src="../../assets/img/visualisation/img-legend.png" alt="" role="presentation" width="400px" />

      <h3>Spacing </h3>
      <img src="../../assets/img/visualisation/img-card-spacing.png" alt="" role="presentation" width="500px" />
    </section>

    <!-- OTHER ELEMENTS-->
    <section>
      <h2 id="other">Other elements</h2>
      <h3>Cards</h3>
      <p>Use cards to differentiate charts within a page. The card background colour is White (W); 6pt corner radius, dropshadow RB20, blur 10pt.</p>
      <img src="../../assets/img/visualisation/img-card.png" alt="" role="presentation"/>

      <h3>Timeframe</h3>
      <p>To indicate a period of time, you can use a button group or a date picker. Labels are in Arial Regular 12pt RG70. Selected status RB.</p>
      <img src="../../assets/img/visualisation/img-timeframe.png" alt="" role="presentation" width="500px"/>
      <p>To see how to change status button from selected to active and vice versa, follow our <a href="#tutorial">tutorial</a>.</p>

      <h3>Tooltips</h3>
      <p>Tooltips are a great tool to provide additional information when hovering a chart.<br/> 
We anchor tooltips at the cursor. <br/> 
The flag is RB at 90% opacity and has a small arrow that points to the highest reasonable point of a chart. The pole is RB at 100% opacity and should be centered to the hovering element. <br/> <br/>

Heading is Arial Bold 12pt in White (W). Copy is Arial Regular 12pt in W. </p>
      <img src="../../assets/img/visualisation/img-tooltip-simple.png" alt="" role="presentation"/>
      <img src="../../assets/img/visualisation/img-tooltip-multiple-values.png" alt="" role="presentation"/>
    </section>

    <!-- PALETTE -->
    <section class="palette">
      <h2 id="palette">Palette</h2>
      <p>We use our neutral palette as background and foreground, reserving bright tones for data visualisation.</p>

      <h3>Foreground</h3>
      <!-- Foreground -->
      <div class="flex-container font-14">
        <div class="w-50">
          Use RB in titles, headings, figures and selected statuses. 
        </div>
        <div class="w-50">
          <Color :name="'ROYAL BLUE (RB)'" :pantone="'Coated 288C / Uncoated 288U'" :cmyk="'100, 75, 0, 40'" :rgb="'28, 47, 94'" :hex="'#1C2F5E;'" />
        </div>
      </div>
      <div class="flex-container font-14">
        <div class="w-50">
          Use RB70 in labels, buttons and tables.
        </div>
        <div class="w-50">
          <Color :name="'RB70'" :cmyk="'18, 13, 0, 50'" :rgb="'81, 94, 128'" :hex="'#515E80'" /><br/>
        </div>
      </div>
      <div class="flex-container font-14">
        <div class="w-50">
          Use RB40 in subtle elements such as grids.
        </div>
        <div class="w-50">
          <Color :name="'RB40'" :cmyk="'11, 7, 0, 25'" :rgb="'164, 172, 191'" :hex="'#A4ACBF'" />
        </div>
      </div>

      <h3>Background</h3>
      <!-- Background -->
      <div class="flex-container font-14">
        <div class="w-50">
          Use White (W) as tile background. 
        </div>
        <div class="w-50">
          <Color :name="'WHITE (W)'" :cmyk="'0, 0, 0, 0'" :rgb="'256, 256, 256'" :hex="'#FFFFFF'" />
        </div>
      </div>

      <div class="flex-container font-14">
        <div class="w-50">
          Use BG as page background, to alternate row background color in a table, and as background when figures are placed in a card.
        </div>
        <div class="w-50">
          <Color :name="'BG'" :cmyk="'2, 2, 0, 2'" :rgb="'244, 245, 250'" :hex="'#F4F5FA'" />
        </div>
      </div>

      <h3>Proto-palette</h3>
      <p>These colors are the building blocks we use to craft pre-defined combinations and sub-palettes within data visualization. We do not use these directly in charts and graphs. Using pre-defined groupings will help foster consistency, predictability, and accessibility.</p>
      <img src="../../assets/img/visualisation/img-proto-palette.png" alt="" role="presentation"/>

      <h3>Categorical palette</h3>
      <p>The colors for our complex charts come from our predefined categorical combos. Generally, we try to vary value, saturation, and hue between categories. When building a data story, choose from one of the established combos for visual harmony and accessibility optimization. Feel free to use more than one combo when creating multiple charts on a page.</p>
      <img src="../../assets/img/visualisation/img-combo-palette.png" alt="" role="presentation" width="500px"/>
    </section>

    <!-- Tutorial -->
    <section>
      <h2 id="tutorial">Tutorial</h2>
      <h3>Change button stage</h3>
      <p>Follow this tutorial to understand how to swap stages in button group elements.</p>
      <img src="../../assets/img/visualisation/img-tutorial.png" alt="" role="presentation" width="450"/>

    </section>

  </div>
</template>

<script>
import AnchorMenu from "../common/AnchorMenu";
import Color from "./colors/Color.vue"
import { dataVisualisationAnchors } from "../../assets/data/anchors";

export default {
  name: 'DataVisualisation',
  data: () => {
    return {
      anchors: dataVisualisationAnchors,
    };
  },
  components: {
    AnchorMenu,
    Color
  },
}
</script>